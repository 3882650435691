import {graphql} from "gatsby"
import React from "react"
import CallToAction from "../components/callToAction"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import {CTA, StrapiSharpImage} from "../utils/types"

interface Props {
  readonly data: PageQueryData
  readonly pageContext: {
    previous?: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    next?: any
  }
}

interface Author {
  id: string
  fullName: string
  jobTitle: string
  avatar: StrapiSharpImage
}

interface ArticleContentBlock {
  id: string
  text: string
  CTA?: CTA
}

interface ArticleHeader {
  id: string
  title: string
  subtitle: string
  className?: string
}
interface ContentMedia {
  media: StrapiSharpImage
}

interface Article extends ArticleHeader {
  author: Author
  metaDescription?: string
  intro?: string
  tagline?: string
  headerImage?: StrapiSharpImage
  content: Array<ArticleContentBlock>
  contentMedia: Array<ContentMedia>
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  article: Article
}

const ArticleHeader: React.FC<ArticleHeader> = ({id, title, subtitle, className = ""}) => {
  return (
    <div className={`text-black pt-8 md:pt-12 lg:pt-24 md:container ${className}`}>
      <div className="w-full py-5 px-3 text-center md:text-left xl:6/12 md:w-9/12 lg:w-7/12 md:py-12 md:px-16 bg-tercen-yellow">
        <h1 className="font-black text-5xl md:text-6xl">{title}</h1>
        <h3 className="text-xl pt-3 md:pt-8 md:text-2xl ">{subtitle}</h3>
      </div>
    </div>
  )
}

const ArticleTemplate: React.FC<Props> = ({data, pageContext}) => {
  const article = data.article

  return (
    <Layout title={article.title} description={article.metaDescription}>
      <article className="pb-24">
        <header className="relative">
          {article.headerImage && (
            <>
            <GatsbyImage
              alt="article header image"
              className=""
              image={article.headerImage.localFile.childImageSharp.gatsbyImageData}
              backgroundColor={`#040e18`}
              style={{minHeight: "220px"}}/>
            <ArticleHeader className="absolute bottom-0 left-0" id={article.id} title={article.title} subtitle={article.subtitle} />
            </>
          )}
          {!article.headerImage && (
            <div className="w-full text-black bg-tercen-blue">
              <ArticleHeader id={article.id} title={article.title} subtitle={article.subtitle} />
            </div>
          )}
        </header>
        <div className="lg:container flex flex-col md:flex-row">
          <div className="text-left items-center text-black p-2 mt-16 md:mt-0 md:text-center md:w-4/12 flex flex-row md:flex-col">
            <div className="w-2/12 md:w-full md:px-16 md:pt-12 md:pb-2 xl:px-20">
              <GatsbyImage className="" image={article.author.avatar.localFile.childImageSharp.gatsbyImageData} alt="Article Author" />
            </div>
            <div className="px-4 w-10/12 text-black md:px-4 md:py-2 md:w-full">
              <div className="text-xl md:text-base">{article.author.fullName}</div>
              <div className="font-extralight md:text-sm">{article.author.jobTitle}</div>
            </div>
            {article.tagline && (
              <div className="hidden md:block md:visible w-full text-2xl text-tercen-blue mt-64 px-8 lg:px-20">
                {article.tagline}
              </div>
            )}
          </div>
          <div className="px-6 md:w-8/12 md:pr-24">
            {article.intro && (
              <div className="article-intro md:py-10" dangerouslySetInnerHTML={{__html: article.intro}} />
            )}
            {article.content.map((contentBlock: ArticleContentBlock, key: int) => {
              return (
                <div key={contentBlock.id}>
                 {article.contentMedia[key].media !== null && article.contentMedia[key].media !== undefined && (
                    <GatsbyImage className="" image={article.contentMedia[key].media.localFile.childImageSharp.gatsbyImageData} alt="media" />
                  )}
                  <div className="article-text" dangerouslySetInnerHTML={{__html: contentBlock.text}} />
                  {contentBlock.CTA && <CallToAction metadata={contentBlock.CTA} />}
                </div>
              )
            })}
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    article: strapiArticle(slug: {eq: $slug}) {
      id
      author {
        fullName
        jobTitle
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 60)
            }
          }
        }
      }
      metaDescription
      title
      subtitle
      intro
      tagline
      headerImage {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 60, layout: FULL_WIDTH)
          }
        }
      }
      content
      contentMedia {
        media {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1400, quality: 60) 
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate

/**
 * {
        id
        text
        media {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(maxWidth: 1400, quality: 60) 
            }
          }
        }
        CTA {
          URL
          class
          id
          text
        }
      }
 */
